import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { numberWithCommas } from 'src/helpers';
import {
  paymentStripeRequest,
  transactionRequest,
} from 'src/redux/payment/actions';

import {
  LABEL_TRACKING,
  ACTION_TRACKING,
  CATEGORY_TRACKING,
} from 'src/constants';
import googleAnalytic from 'src/helpers/googleAnalytic';

import visa from 'src/assets/images/Icons/visaLogo.png';
import mastercard from 'src/assets/images/Icons/masterCardLogo.png';
import amex from 'src/assets/images/Icons/creditLogo.png';
import discover from 'src/assets/images/Icons/coverLogo.png';
import { PRICE_BEEBUX } from '../../constants';
import { useTranslation } from 'react-i18next';
import userIcon from 'src/assets/images/Icons/icon-user.svg';
import walletIcon from 'src/assets/images/Icons/Creditcard.svg';
import dateIcon from 'src/assets/images/Icons/Group.svg';
import secureIcon from 'src/assets/images/Icons/Secure.svg';

const PaymentCreditCardDetail = ({ callback }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const walletData = useSelector((state) => state.Users?.wallet);
  const [currency, setCurrency] = useState(20000);
  const [priceId, setPriceId] = useState(0);
  const [price, setPrice] = useState(20000);
  const [isProcessing, setIsProcessing] = useState(false);

  const formik = useFormik({
    initialValues: {
      cardNumber: '',
      expDate: '',
      cvc: '',
      holderName: '',
    },
    validationSchema: Yup.object({
      cardNumber: Yup.string().required('Required'),
      expDate: Yup.string().required('Required'),
      cvc: Yup.string().required('Required'),
      holderName: Yup.string(),
    }),
    onSubmit: (values) => {
      googleAnalytic(
        CATEGORY_TRACKING.USER,
        ACTION_TRACKING.CLICK,
        LABEL_TRACKING.PLACE_ORDER
      );
      onSubmit(values);
    },
  });

  const handleCLickCurency = (id, price) => {
    setPriceId(id);
    setCurrency(price);
    setPrice(price);
  };

  const handleChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '').slice(0, 7);
    setPrice(inputValue);
  };
  const handleBlurAmount = (e) => {
    let id = '';
    switch (Number(price)) {
      case PRICE_BEEBUX[0].currency:
        id = 0;
        break;
      case PRICE_BEEBUX[1].currency:
        id = 1;
        break;
      case PRICE_BEEBUX[2].currency:
        id = 2;
        break;
      case PRICE_BEEBUX[3].currency:
        id = 3;
        break;
      case PRICE_BEEBUX[4].currency:
        id = 4;
        break;
      case PRICE_BEEBUX[5].currency:
        id = 5;
        break;

      default:
        break;
    }

    if (id !== '') {
      setPriceId(id);
      setCurrency(PRICE_BEEBUX[id].currency);
    } else {
      setPriceId('');
      setCurrency('');
    }

    if (price === '') {
      setPriceId(0);
      setCurrency(20000);
    }
  };

  const handleChangeExpDate = (e) => {
    const inputValue = e.target.value;
    const checkLength = inputValue.replace(/ /g, '').length;

    if (inputValue.includes('/')) {
      if (checkLength > 5) return;
    } else {
      if (checkLength > 4) return;
    }

    let formattedValue = inputValue.replace(/[^/\0-9]/g, ''); // Remove existing commas

    if (formattedValue.length === 3 && !formattedValue.includes('/')) {
      formattedValue =
        formattedValue.substring(0, 2) +
        '/' +
        formattedValue.substring(2, formattedValue.length);
    }
    e.target.value = formattedValue;
    formik.handleChange(e);
  };
  const formattedNumber = price ? Number(price).toLocaleString() : '';
  const onSubmit = (values) => {
    setIsProcessing(true);
    dispatch(
      paymentStripeRequest(values, (data) => {
        if (data?.error) {
          toast.error(t('toast.invalid_card_information'));
          setIsProcessing(false);
          return;
        }
        if (!data?.id) return;
        dispatch(
          transactionRequest(
            {
              amount: price ? Number(price) : currency,
              payment_method: 'STRIPE',
              payment_method_id: data?.id,
            },
            () => {
              setIsProcessing(false);
              toast.success(t('toast.success'));
              if (callback) callback();
            }
          )
        );
      })
    );
  };

  return (
    <div className="mx-auto ">
      <div className="payment">
        <div className="payment-left p-4">
          <div className="payment-left_text">
            <span>{t('description_credit_payment_1')}</span>
            <span>{t('description_credit_payment_2')}</span>
            <span>{t('description_credit_payment_3')}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: t('description_credit_payment_4'),
              }}
            ></span>
          </div>
          <div className="wrapper-blance">
            <div className="wrapper-blance_left">
              {t('your_current_balance')}:
            </div>
            <div className="wrapper-blance_right">
              {numberWithCommas(walletData.balance)} beebux
            </div>
          </div>

          <div className="wrapper_buy-beebux_card">
            {PRICE_BEEBUX.map((item) => (
              <div
                key={item.id}
                className={`buy-beebux_card ${
                  priceId === item.id ? 'buy-beebux_card-active' : ''
                }`}
                onClick={() => handleCLickCurency(item.id, item.currency)}
              >
                <div className="currency ">
                  {numberWithCommas(item.currency)} VND
                </div>
                <div className="currency_beebux">
                  {t('buy')} {numberWithCommas(item.currencyBeebux)} beebux
                </div>
              </div>
            ))}
          </div>
          <div className="money">{t('description_credit_payment_5')}</div>
          <Input
            type="text"
            value={formattedNumber}
            onChange={handleChange}
            onBlur={(e) => handleBlurAmount(e)}
          />
          <div className="money">
            <span>{t('note')}</span>1 beebux = 100 VND
          </div>
        </div>
        <div className="payment-right p-4">
          <div
            className="text-center pb-3"
            style={{ fontSize: '20px', fontWeight: '700', lineHeight: '30px' }}
          >
            {t('payment_card_information')}
          </div>
          <div className="list-payment-card pb-3">
            <img alt="" src={visa} className="payment-card" />
            <img alt="" src={mastercard} className="payment-card" />
            <img alt="" src={amex} className="payment-card" />
            <img alt="" src={discover} className="payment-card" />
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup className="container_form_group">
              <Label className="font-size-5 title_card">
                {t('card_number')} <span className="required">*</span>
              </Label>
              <InputGroup className="input-group-lg card_input_contai">
                <InputGroupAddon addonType="prepend">
                  <span className="input-group-text icon_input  text-muted">
                    <img src={walletIcon} alt="wallet" />
                  </span>
                </InputGroupAddon>
                <Input
                  type="text"
                  maxLength="16"
                  id="cardNumber"
                  name="cardNumber"
                  className="form-control text_input"
                  placeholder={t('card_number')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.cardNumber && !isNaN(formik.values.cardNumber)
                      ? Number(formik.values.cardNumber)
                      : ''
                  }
                  invalid={
                    formik.touched.cardNumber && formik.errors.cardNumber
                      ? true
                      : false
                  }
                />
                {formik.touched.cardNumber && formik.errors.cardNumber ? (
                  <FormFeedback type="invalid">
                    {formik.errors.cardNumber}
                  </FormFeedback>
                ) : null}
              </InputGroup>
            </FormGroup>
            <Row>
              <Col md={7}>
                <FormGroup className="container_form_group">
                  <Label className="title_card">
                    {t('expiry_date')} <span className="required">*</span>
                  </Label>
                  <InputGroup className=" input-group-lg  card_input_contai">
                    <InputGroupAddon addonType="prepend">
                      <span className="input-group-text icon_input  text-muted">
                        <img src={dateIcon} height="16px" alt="date" />
                      </span>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      id="expDate"
                      name="expDate"
                      className="form-control text_input"
                      placeholder="MM/YY"
                      onChange={(e) => handleChangeExpDate(e)}
                      value={formik.values.expDate}
                      invalid={
                        formik.touched.expDate && formik.errors.expDate
                          ? true
                          : false
                      }
                    />
                    {formik.touched.expDate && formik.errors.expDate ? (
                      <FormFeedback type="invalid">
                        {formik.errors.expDate}
                      </FormFeedback>
                    ) : null}
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup className="container_form_group">
                  <Label className="title_card">
                    CVV <span className="required">*</span>
                  </Label>
                  <InputGroup className="card_input_contai input-group-lg ">
                    <InputGroupAddon addonType="prepend">
                      <span className="input-group-text icon_input text-muted">
                        <img src={secureIcon} alt="secure" />
                      </span>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      maxLength="4"
                      id="cvc"
                      name="cvc"
                      className="form-control text_input"
                      placeholder="CVV"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.cvc && !isNaN(formik.values.cvc)
                          ? Number(formik.values.cvc)
                          : ''
                      }
                      invalid={
                        formik.touched.cvc && formik.errors.cvc ? true : false
                      }
                    />
                    {formik.touched.cvc && formik.errors.cvc ? (
                      <FormFeedback type="invalid">
                        {formik.errors.cvc}
                      </FormFeedback>
                    ) : null}
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup className="container_form_group">
              <Label className="title_card">{t('card_holder_name')}</Label>
              <InputGroup className=" input-group-lg card_input_contai">
                <InputGroupAddon addonType="prepend">
                  <span className="input-group-text icon_input text-muted">
                    <img src={userIcon} width="16px" height="16px" alt="user" />
                  </span>
                </InputGroupAddon>
                <Input
                  type="text"
                  id="holderName"
                  name="holderName"
                  className="form-control text_input"
                  placeholder={t('card_holder_name')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.holderName}
                  invalid={
                    formik.touched.holderName && formik.errors.holderName
                      ? true
                      : false
                  }
                />
                {formik.touched.holderName && formik.errors.holderName ? (
                  <FormFeedback type="invalid">
                    {formik.errors.holderName}
                  </FormFeedback>
                ) : null}
              </InputGroup>
            </FormGroup>
            <div className="w-100 text-center">
              {t('description_credit_payment_6')}
            </div>
            <div className="w-100 text-center">
              <a
                style={{ color: '#F9C901' }}
                target="_blank"
                rel="noopener noreferrer"
                href={t('Link Privacy policy')}
              >
                {t('Privacy policy')}
              </a>
              <span> {t('and')} </span>
              <a
                style={{ color: '#F9C901' }}
                rel="noopener noreferrer"
                target="_blank"
                href={t('Link Terms')}
              >
                {t('terms_of_services')}
              </a>
            </div>
            <div>
              <Button
                style={{
                  background: '#F9C901',
                  borderRadius: '30px',
                  marginTop: '10px',
                  padding: '12px 0px',
                  border: '1px solid #F9C901',
                }}
                disabled={isProcessing}
                block
                className="text-primary submit-contai"
                type="submit"
              >
                {isProcessing ? (
                  <Spinner animation="border" variant="warning" />
                ) : (
                  <span style={{ color: '#000', fontWeight: 'bold' }}>
                    {t('place_secure_order').toLocaleUpperCase()}
                  </span>
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PaymentCreditCardDetail;
