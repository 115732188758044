import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { PAYMENT_TYPE } from '../../constants';
import { useTranslation } from 'react-i18next';

const PaymentDetail = ({ title }) => {
  const { t } = useTranslation();
  const listMethodPayment = useSelector(
    (state) => state.Transactions.listMethodPayment
  );
  const inputRef = useRef(null);
  const name = PAYMENT_TYPE[title].value().toLocaleLowerCase();

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      toast.success(t('toast.copy_success'));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="payment-detail">
      <div>
        <p>{t('description_payment_1', { name })}</p>
        <p>{t('description_payment_2', { name })}</p>
        <div className="phone-number">
          <input
            ref={inputRef}
            value={listMethodPayment[title]?.phone}
            disabled
          />
          <i
            className="ri-file-copy-2-line"
            onClick={() => copyToClipBoard(inputRef.current.value)}
          ></i>
        </div>
        <p className="opcity_Strong">
          <strong>
            {t('description_payment_3', { name })} 1 beebux = 100 VND
          </strong>
          <br />
          <strong>{t('description_payment_4')}</strong>
        </p>
        <p>{t('description_payment_6')}</p>
        <p>{t('description_payment_7', { name })}</p>
      </div>
      <div className="payment-img">
        <img src={listMethodPayment[title]?.imgUrl} alt="img_bank" />
      </div>
      <p
        className="mt-3 mb-5 text-center"
        style={{ color: '#000', fontSize: '14px' }}
      >
        {t('description_payment_8')} <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#F9C901' }}
          href={t('Link Privacy policy')}
        >
          {t('Privacy policy')}
        </a>{' '}
        {t('and')}{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#F9C901' }}
          href={t('Link Terms')}
        >
          {t('terms_and_conditions')}
        </a>
      </p>
    </div>
  );
};

export default PaymentDetail;
