import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import coinIcon from 'src/assets/images/Icons/coinIcon.svg';

const BasicModal = ({
  isOpen,
  title,
  children,
  handleCloseModal,
  className,
  isAction,
  onSubmit,
  stepConfirm,
  backHandle,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        className={className || 'modal-payment'}
        isOpen={isOpen}
        toggle={handleCloseModal}
        centered={true}
      >
        <ModalHeader className="" toggle={handleCloseModal}>
          {stepConfirm ? (
            <div className="title_img">
              <img src={coinIcon} alt="Cash" />
            </div>
          ) : (
            ''
          )}
          {title.toLocaleUpperCase()}
          {backHandle && backHandle}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        {isAction ? (
          <ModalFooter>
            <button className="button-outline" onClick={handleCloseModal}>
              {t('cancel').toLocaleUpperCase()}
            </button>
            <button color="primary" className="button-bg" onClick={onSubmit}>
              {stepConfirm
                ? t('buy').toLocaleUpperCase() + ' BEEBUX'
                : t('ok').toLocaleUpperCase()}
            </button>
          </ModalFooter>
        ) : null}
      </Modal>
    </div>
  );
};

export default BasicModal;
