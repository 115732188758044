import React from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';

const EmptyData = ({ icon, title, description }) => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-3">
      <Card className="empty-data-card" style={{ backgroundColor: 'unset' }}>
        <CardBody className="text-center">
          <CardTitle>{title}</CardTitle>
          <CardText>{description}</CardText>
        </CardBody>
      </Card>
    </div>
  );
};

export default EmptyData;
