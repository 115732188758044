import {
  FETCH_COURSE,
  FETCH_COURSE_DETAIL,
  FETCH_COURSE_DETAIL_SUCCESS,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_LECTURE_SUCCESS,
  FETCH_COURSE_LECTURE,
  FETCH_LECTURE,
  FETCH_LECTURE_SUCCESS,
  FETCH_COURSE_SECTIONS,
  FETCH_COURSE_SECTIONS_SUCCESS,
  BUY_COURSE,
  CREATE_RATE_COURSE,
  CREATE_RATE_COURSE_SUCCESS,
  FETCH_RATE,
  FETCH_RATE_SUCCESS,
  FETCH_TEACHER,
  FETCH_TEACHER_SUCCESS,
  CREATE_REPLY_RATE,
  CREATE_REPLY_RATE_SUCCESS,
  EDIT_RATE,
  EDIT_RATE_SUCCESS,
  FETCH_COURSE_FAIL,
} from './constants';

export const fetchRate = (payload) => ({
  type: FETCH_RATE,
  payload,
});

export const fetchRateSuccess = (payload) => ({
  type: FETCH_RATE_SUCCESS,
  payload,
});

export const fetchCourse = (payload) => ({
  type: FETCH_COURSE,
  payload,
});
export const fetchCourseFail = (payload) => ({
  type: FETCH_COURSE_FAIL,
  payload,
});

export const fetchCourseSuccess = (payload) => ({
  type: FETCH_COURSE_SUCCESS,
  payload,
});

export const fetchCourseDetail = (payload) => {
  return {
    type: FETCH_COURSE_DETAIL,
    payload,
  };
};

export const fetchCourseDetailSuccess = (payload) => ({
  type: FETCH_COURSE_DETAIL_SUCCESS,
  payload,
});

export const fetchCourseLecture = (payload) => ({
  type: FETCH_COURSE_LECTURE,
  payload,
});

export const fetchCourseLectureSuccess = (payload) => ({
  type: FETCH_COURSE_LECTURE_SUCCESS,
  payload,
});

export const fetchLecture = (payload) => {
  return {
    type: FETCH_LECTURE,
    payload,
  };
};

export const fetchLectureSuccess = (payload) => {
  return {
    type: FETCH_LECTURE_SUCCESS,
    payload,
  };
};

export const fetchCourseSection = (payload) => {
  return {
    type: FETCH_COURSE_SECTIONS,
    payload,
  };
};

export const fetchCourseSectionSuccess = (payload) => {
  return {
    type: FETCH_COURSE_SECTIONS_SUCCESS,
    payload,
  };
};

export const buyCourse = (payload, callback) => ({
  type: BUY_COURSE,
  payload,
  callback,
});

export const createRate = (payload) => {
  return {
    type: CREATE_RATE_COURSE,
    payload,
  };
};

export const createRateSuccess = (payload) => {
  return {
    type: CREATE_RATE_COURSE_SUCCESS,
    payload,
  };
};

export const fetchTeacher = (payload) => {
  return {
    type: FETCH_TEACHER,
    payload,
  };
};

export const fetchTeacherSuccess = (payload) => {
  return {
    type: FETCH_TEACHER_SUCCESS,
    payload,
  };
};

export const createReplyRate = (payload) => {
  return {
    type: CREATE_REPLY_RATE,
    payload,
  };
};
export const createReplyRateSuccess = (payload) => {
  return {
    type: CREATE_REPLY_RATE_SUCCESS,
    payload,
  };
};
export const editRate = (payload) => {
  return {
    type: EDIT_RATE,
    payload,
  };
};
export const editRateSuccess = (payload) => {
  return {
    type: EDIT_RATE_SUCCESS,
    payload,
  };
};
