import config from "src/config";

const googleAnalytic = (category_value, action_value, label_value) => {
  if (config.ENV === 'production') {
    window.gtag('event', label_value, {
      'app_name': 'Pombeebee Web Portal',
      'category': category_value,
      'action': action_value
    });
    return;
  }
};

export default googleAnalytic;
