import { masterItemParser } from 'src/helpers/masterData';
import { GET_CATEGORIES_COURSE_SUCCESS, GET_GENERAL_DATA_SUCCESS, SET_INITILIZED_DATA } from './constants';

const INIT_STATE = {
  generalData: {
    labels: [],
    topics: [],
  },
  initializedData: false,
  course_categories: [],
};

const GeneralData = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GENERAL_DATA_SUCCESS: {
      var topics = [];
      let apiTopics = action.payload.topics
      for (let index = 0; index < apiTopics.length; index++) {
        topics.push(masterItemParser(apiTopics[index]))
      }

      var labels = [];
      let apiLabels= action.payload.labels
      for (let index = 0; index < apiLabels.length; index++) {
        labels.push(masterItemParser(apiLabels[index]))
      }

      return {
        ...state,
        generalData: {
          labels: labels,
          topics: topics
        },

      };
    }
    case SET_INITILIZED_DATA: {
      return {
        ...state,
        initializedData: action.payload,
      };
    }
    case GET_CATEGORIES_COURSE_SUCCESS: {
      return {
        ...state,
        course_categories: action.payload.course_categories
      }
    }
    default:
      return { ...state };
  }
};

export default GeneralData;
