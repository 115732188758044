const {
  FETCH_POST_LIST,
  FETCH_POST_LIST_SUCCESS,
  // CREATE_POST_SUCCESS,
  LIKE_POST_SUCCESS,
  GET_POST_BY_ID_SUCCESS,
  COMMENT_POST_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  EDIT_COMMENT_POST_SUCCESS,
  SETTING_NOTIFICATION_POST_SUCCESS,
  SAVE_OR_UNSAVE_POST_SUCCESS,
  GET_COMMENT_POST_ID_SUCCESS,
  GET_COMMENT_POST_ID,
  DELETE_POST_SUCCESS,
  EDIT_POST_SUCCESS,
  TOGGLE_POST_COMMENT,
  GET_POST_BY_ID,
  GET_POST_NON_AUTH,
  TOOGLE_PIN_POST_SUCCESS,
} = require('./constants');

const initialState = {
  posts: [],
  lastPage: 0,
  post: null,
  processing: {
    value: false,
    id: '',
  },
  postLoading: false,
};

const Community = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POST_LIST:
      return {
        ...state,
        postLoading: true,
      };
    case FETCH_POST_LIST_SUCCESS:
      const { data, last_page, current_page } = action.payload;
      return {
        ...state,
        posts: current_page === 1 ? data : [...state.posts, ...data],
        lastPage: last_page,
        postLoading: false,
      };
    case LIKE_POST_SUCCESS:
      const { data: postData, postId, isFromPostDetail } = action.payload;

      if (isFromPostDetail) {
        return {
          ...state,
          post: Object.assign({}, state.post, {
            is_liked: postData.is_liked,
            likes_count: postData.is_liked
              ? state.post.likes_count + 1
              : state.post.likes_count - 1,
          }),
        };
      }

      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post.id === postId) {
            return {
              ...post,
              is_liked: postData.is_liked,
              likes_count: postData.is_liked
                ? post.likes_count + 1
                : post.likes_count - 1,
            };
          }
          return post;
        }),
      };
    case TOOGLE_PIN_POST_SUCCESS:
      if (action.payload.isFromPostDetail) {
        return {
          ...state,
          post: Object.assign({}, state.post, {
            is_pin: !state.post?.is_pin,
          }),
        };
      }

      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post.id === action.payload.postId) {
            return {
              ...post,
              is_pin: !post.is_pin,
            };
          }
          return post;
        }),
      };
    case GET_POST_BY_ID: {
      return {
        ...state,
        postLoading: true,
      };
    }
    case GET_POST_NON_AUTH: {
      return {
        ...state,
        postLoading: true,
      };
    }
    case GET_POST_BY_ID_SUCCESS: {
      const { post, comments } = action.payload;
      post.comments = [...comments];
      return {
        ...state,
        post: { ...post },
        postLoading: false,
      };
    }

    case COMMENT_POST_SUCCESS: {
      const { post, ...newComment } = action.payload;
      const newPost = { ...state.post };
      const updatePosts = [...state.posts];
      let updatePost = newPost;
      if (updatePosts.length > 0) {
        const tempPost = updatePosts.find(
          (postItem) => postItem.id === newComment.post_id
        );
        if (tempPost) {
          updatePost = tempPost;
        }
      }
      if (!newComment.parent_id) {
        updatePost.comments.push(newComment);
      } else {
        const parentComment = updatePost.comments.find(
          (cmt) => cmt.id === parseInt(newComment.parent_id)
        );
        if (parentComment) {
          parentComment.replies.push(newComment);
        }
      }
      updatePost.comments_count++;
      if (updatePosts.length > 0) {
        const indexPost = updatePosts.findIndex(
          (postItem) => postItem.id === newComment.post_id
        );
        if (indexPost) {
          updatePosts[indexPost] = updatePost;
        }
      }
      return {
        ...state,
        post: updatePost,
        posts: updatePosts,
      };
    }

    case DELETE_COMMENT_SUCCESS: {
      const { id, parent_id, post_id } = action.payload;
      const newPost = { ...state.post };
      const updatePosts = [...state.posts];
      let updatePost = newPost;
      if (updatePosts.length > 0) {
        const tempPost = updatePosts.find(
          (postItem) => postItem.id === post_id
        );
        if (tempPost) {
          updatePost = tempPost;
        }
      }
      if (!parent_id) {
        updatePost.comments = updatePost.comments.filter(
          (cmt) => cmt.id !== id
        );
      } else {
        const parentComment = updatePost.comments.find(
          (cmt) => cmt.id === parseInt(parent_id)
        );
        if (parentComment) {
          parentComment.replies = parentComment.replies.filter(
            (reply) => reply.id !== id
          );
        }
      }
      updatePost.comments_count--;

      if (updatePosts.length > 0) {
        const indexPost = updatePosts.findIndex(
          (postItem) => postItem.id === post_id
        );
        if (indexPost) {
          updatePosts[indexPost] = updatePost;
        }
      }
      return {
        ...state,
        post: updatePost,
        posts: updatePosts,
      };
    }
    case EDIT_COMMENT_POST_SUCCESS: {
      const comment = action.payload.cmt;
      const content = action.payload.comment;
      const newPost = { ...state.post };
      const updatePosts = [...state.posts];
      let updatePost = newPost;
      if (updatePosts.length > 0) {
        const tempPost = updatePosts.find(
          (postItem) => postItem.id === comment.post_id
        );
        if (tempPost) {
          updatePost = tempPost;
        }
      }
      if (!comment.parent_id) {
        updatePost.comments = updatePost.comments.map((cmt) => {
          if (cmt.id === parseInt(comment.id)) {
            return {
              ...cmt,
              content: content,
            };
          }
          return cmt;
        });
      } else {
        const parentComment = updatePost.comments.find(
          (cmt) => cmt.id === parseInt(comment.parent_id)
        );
        if (parentComment) {
          parentComment.replies = parentComment.replies.map((rep) => {
            if (rep.id === parseInt(comment.id)) {
              return {
                ...rep,
                content: content,
              };
            }
            return rep;
          });
        }
      }
      if (updatePosts.length > 0) {
        const indexPost = updatePosts.findIndex(
          (postItem) => postItem.id === comment.post_id
        );
        if (indexPost) {
          updatePosts[indexPost] = updatePost;
        }
      }
      return {
        ...state,
        post: updatePost,
        posts: updatePosts,
      };
    }
    case SETTING_NOTIFICATION_POST_SUCCESS: {
      const { postId, status, user, response } = action.payload;
      const updateUser = response.data.setting_notifications.find(
        (resUser) => resUser.id === user.id
      );
      const newPost = { ...state.post };
      const updatePosts = [...state.posts];
      let updatePost = newPost;
      if (updatePosts.length > 0) {
        const tempPost = updatePosts.find((postItem) => postItem.id === postId);
        if (tempPost) {
          updatePost = tempPost;
        }
      }
      updatePost.has_notification = !updatePost.has_notification;
      if (status) {
        const userIndex = updatePost.setting_notifications.findIndex(
          (notiUser) => notiUser.id === user.id
        );
        if (userIndex !== -1) {
          updatePost.setting_notifications[userIndex] = updateUser;
        } else {
          updatePost.setting_notifications.push(updateUser);
        }
      } else {
        updatePost.setting_notifications =
          updatePost.setting_notifications.filter(
            (notiUser) => notiUser.id !== user.id
          );
      }
      if (updatePosts.length > 0) {
        const indexPost = updatePosts.findIndex(
          (postItem) => postItem.id === postId
        );
        if (indexPost) {
          updatePosts[indexPost] = updatePost;
        }
      }
      return {
        ...state,
        post: updatePost,
        posts: updatePosts,
      };
    }
    case SAVE_OR_UNSAVE_POST_SUCCESS: {
      const { postId } = action.payload;
      const newPost = { ...state.post };
      const updatePosts = [...state.posts];
      let updatePost = newPost;
      if (updatePosts?.length > 0) {
        const tempPost = updatePosts.find((postItem) => postItem.id === postId);
        if (tempPost) {
          updatePost = tempPost;
        }
      }
      updatePost.is_saved = !updatePost.is_saved;
      if (updatePosts.length > 0) {
        const indexPost = updatePosts.findIndex(
          (postItem) => postItem.id === postId
        );
        if (indexPost) {
          updatePosts[indexPost] = updatePost;
        }
      }

      return {
        ...state,
        post: updatePost,
        posts: updatePosts,
      };
    }

    case GET_COMMENT_POST_ID: {
      return {
        ...state,
        processing: {
          value: true,
          id: action.payload,
        },
      };
    }

    case GET_COMMENT_POST_ID_SUCCESS: {
      const { postId, comments } = action.payload;
      const updatePosts = [...state.posts];
      const indexPost = updatePosts.findIndex(
        (postItem) => postItem.id === postId
      );
      if (indexPost !== -1) {
        updatePosts[indexPost].comments = [...comments];
      }
      return {
        ...state,
        posts: updatePosts,
        processing: {
          value: false,
          id: postId,
        },
      };
    }

    case DELETE_POST_SUCCESS: {
      const { id } = action.payload;
      let updatePosts = [...state.posts];
      updatePosts = updatePosts.filter((postItem) => postItem.id !== id);
      return {
        ...state,
        posts: updatePosts,
      };
    }
    case EDIT_POST_SUCCESS: {
      const { id } = action.payload;
      const newPost = { ...state.post };
      const updatePosts = [...state.posts];
      let updatePost = newPost;
      updatePost = action.payload;
      if (updatePosts.length > 0) {
        const indexPost = updatePosts.findIndex(
          (postItem) => postItem.id === id
        );
        if (indexPost !== -1) {
          updatePosts[indexPost] = updatePost;
        }
      }
      return {
        ...state,
        post: updatePost,
        posts: updatePosts,
      };
    }
    case TOGGLE_POST_COMMENT: {
      const togglePostCommentId = action.payload;
      state.posts.forEach((post, index) => {
        if (post.id === togglePostCommentId) {
          if (
            post.isOpenComment === undefined ||
            post.isOpenComment === false
          ) {
            post.isOpenComment = true;
          } else {
            post.isOpenComment = false;
          }
        }
      });
      return {
        ...state,
        posts: [...state.posts],
      };
    }

    default:
      return state;
  }
};

export default Community;
