import React from 'react';
import { Redirect } from 'react-router-dom';
import config from 'src/config';

// lazy load all the views
const Chat = React.lazy(() => import('../pages/Chat/index'));
const StarterPage = React.lazy(() => import('../pages/StarterPage/index'));

// auth
const Login = React.lazy(() => import('../pages/Auth/Login'));
const Logout = React.lazy(() => import('../pages/Auth/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/Auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/Auth/ResetPassword'));
const Register = React.lazy(() => import('../pages/Auth/Register'));
const LockScreen = React.lazy(() => import('../pages/Auth/LockScreen'));
const Profile = React.lazy(() => import('../pages/Profile'));
const Library = React.lazy(() => import('../pages/Library'));
const LibraryDetail = React.lazy(() => import('../pages/Library/detail'));
const Transactions = React.lazy(() => import('../pages/Transactions'));
const Help = React.lazy(() => import('../pages/HelpCenter'));
const ChangePassword = React.lazy(() =>
  import('../pages/Single/ChangePassword')
);
const OTPVerifyScreen = React.lazy(() =>
  import('../pages/Auth/OTPVerifyScreen')
);
const Community = React.lazy(() => import('../pages/Community'));
const CommunityDetail = React.lazy(() =>
  import('../pages/Community/DetailPage')
);
const PostNonAuth = React.lazy(() =>
  import('../pages/Community/DetailPage/postNonAuth')
);
const Course = React.lazy(() => import('../pages/Course'));
const CourseDetail = React.lazy(() => import('../pages/Course/Detail'));
const TabCourse = React.lazy(() => import('../pages/Course/TabCourse'));

// declare all routes
const authProtectedRoutes = [
  { path: '/chat', component: Chat },
  { path: '/library/:id', component: LibraryDetail },
  { path: '/library', component: Library },
  { path: '/transaction', component: Transactions },
  { path: '/pages-starter', component: StarterPage },
  { path: '/logout', component: Logout },
  { path: '/profile', component: Profile },
  { path: '/forget-password', component: ForgetPassword },
  { path: '/change-password', component: ChangePassword },
  { path: '/help', component: Help },
  { path: '/community', component: Community },
  { path: '/course/:id/:id', component: TabCourse },
  { path: '/course/:id', component: CourseDetail },
  { path: '/course', component: Course },

  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/library" />,
  },
  {
    path: '/profile',
    exact: true,
    component: () => <Redirect to="/profile" />,
  },
  {
    path: '/library',
    exact: true,
    component: () => <Redirect to="/library" />,
  },
  {
    path: '/transaction',
    exact: true,
    component: () => <Redirect to="/transaction" />,
  },
  {
    path: '/payment',
    exact: true,
    component: () => <Redirect to="/payment" />,
  },
  {
    path: '/forget-password',
    exact: true,
    component: () => <Redirect to="/forget-password" />,
  },
  {
    path: '/change-password',
    exact: true,
    component: () => <Redirect to="/change-password" />,
  },
  {
    path: '/help',
    exact: true,
    component: () => <Redirect to="/help" />,
  },
];

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/forget-password', component: ForgetPassword },
  { path: '/lock-screen', component: LockScreen },
  { path: '/otp', component: OTPVerifyScreen },
  { path: '/reset-password', component: ResetPassword },
];

const postRouters = {
  path: '/community/:id',
  component: () => {
    const user = localStorage.getItem(config.STORAGE_KEY_USER);
    const isAuthenticated = !!user;
    return isAuthenticated ? <CommunityDetail /> : <PostNonAuth />;
  },
};

const routes = [...authProtectedRoutes, ...publicRoutes, postRouters];

export { authProtectedRoutes, publicRoutes, routes, postRouters };
