import {
  PAYMENT_STRIPE_FAILD,
  PAYMENT_STRIPE_REQUEST,
  PAYMENT_STRIPE_SUCCESS,
  TRANSACTIONS_REQUEST,
} from './constants';

export const paymentStripeRequest = (payload, callback) => ({
  type: PAYMENT_STRIPE_REQUEST,
  payload,
  callback,
});

export const paymentStripeSuccess = (payload) => ({
  type: PAYMENT_STRIPE_SUCCESS,
  payload,
});

export const paymentStripeFaild = () => ({
  type: PAYMENT_STRIPE_FAILD,
});

export const transactionRequest = (payload, callback) => ({
  type: TRANSACTIONS_REQUEST,
  payload,
  callback,
});
