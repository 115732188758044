import React from 'react';
import { NavItem, NavLink } from 'reactstrap';

export default function SidebarItem({
  Icon,
  id,
  onClick,
  className,
  nameItem,
  unReadHelp,
}) {
  return (
    <NavItem id={id} onClick={onClick} className={className}>
      <div className="circle_out">
        <div className="circle_in">
          <NavLink id="pills-chat-tab">
            {unReadHelp ? (
              <div className="sidebar-noti">{unReadHelp}</div>
            ) : null}
            <img src={Icon} alt={nameItem} width="24px" />
          </NavLink>
        </div>
      </div>
      <h6>{nameItem.toUpperCase()}</h6>
    </NavItem>
  );
}
