export const GET_QUESTIONS_REQUEST = 'GET_QUESTIONS_REQUEST';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILD = 'GET_QUESTIONS_FAILD';

export const GET_QUESTIONS_DETAIL_REQUEST = 'GET_QUESTIONS_DETAIL_REQUEST';
export const GET_QUESTIONS_DETAIL_SUCCESS = 'GET_QUESTIONS_DETAIL_SUCCESS';
export const GET_QUESTIONS_DETAIL_FAILD = 'GET_QUESTIONS_DETAIL_FAILD';

export const FILTER_QUESTIONS_REQUEST = 'FILTER_QUESTIONS_REQUEST';
export const FILTER_QUESTIONS_SUCCESS = 'FILTER_QUESTIONS_SUCCESS';
export const FILTER_QUESTIONS_FAILED = 'FILTER_QUESTIONS_FAILED';

export const QUESTIONS_UNLOCK_REQUEST = 'QUESTIONS_UNLOCK_REQUEST';
export const QUESTIONS_UNLOCK_SUCCESS = 'QUESTIONS_UNLOCK_SUCCESS';
export const QUESTIONS_UNLOCK_FAILD = 'QUESTIONS_UNLOCK_FAILD';

export const QUESTIONS_FAVORITE_REQUEST = 'QUESTIONS_FAVORITE_REQUEST';
export const QUESTIONS_FAVORITE_SUCCESS = 'QUESTIONS_FAVORITE_SUCCESS';
export const QUESTIONS_FAVORITE_FAILD = 'QUESTIONS_FAVORITE_FAILD';
