import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIClient, setAuthorization } from '../../helpers/apiClient';
import {
  COMMENT_POST,
  CREATE_POST,
  DELETE_COMMENT_POST,
  DELETE_POST,
  EDIT_COMMENT_POST,
  FETCH_POST_LIST,
  GET_POST_BY_ID,
  LIKE_POST,
  SAVE_OR_UNSAVE_POST,
  SETTING_NOTIFICATION_POST,
  GET_COMMENT_POST_ID,
  EDIT_POST,
  GET_POST_NON_AUTH,
  TOOGLE_PIN_POST,
} from './constants';
import { getAccessToken } from 'src/helpers/user';
import {
  // createPostSuccess,
  fetchPostListSuccess,
  getPostByIdSuccess,
  likePostSuccess,
  fetchPostList as reFetchPostList,
  commentPostSuccess,
  deleteCommentSuccess,
  editCommentPostSuccess,
  settingNotificationPostSuccess,
  saveOrUnsavePostSuccess,
  getCommentPostIdSuccess,
  deletePostSuccess,
  editPostSuccess,
  tooglePinPostSuccess,
} from './actions';

const get = new APIClient().get;
const create = new APIClient().create;
const update = new APIClient().update;
const deleteApi = new APIClient().delete;

function* fetchPostList({ payload }) {
  setAuthorization(getAccessToken());
  try {
    let response = yield call(get, `/api/posts`, {
      params: payload,
    });
    if (response?.success) {
      yield put(fetchPostListSuccess(response.data));
    }
  } catch (error) {}
}

function* getPostById({ payload }) {
  setAuthorization(getAccessToken());
  try {
    const response = yield call(get, `/api/posts/${payload}`);
    const comments = yield call(get, `/api/posts/${payload}/comments`);
    const data = {
      post: response.data,
      comments: comments.data,
    };
    if (response?.success) {
      yield put(getPostByIdSuccess(data));
    }
  } catch (error) {}
}

function* getPostIdNonAuth({ payload }) {
  try {
    const response = yield call(get, `/api/public/posts/${payload}`);
    const comments = yield call(get, `/api/posts/${payload}/comments`);
    const data = {
      post: response.data,
      comments: comments.data,
    };
    if (response?.success) {
      yield put(getPostByIdSuccess(data));
    }
  } catch (error) {}
}

function* createPost({ payload }) {
  var formData = new FormData();
  formData.append('title', payload.postTitle);
  formData.append('content', payload.content);

  for (let i = 0; i < payload.images.length; i++) {
    formData.append('images[]', payload.images[i]);
  }
  setAuthorization(getAccessToken());
  try {
    let response = yield call(create, '/api/posts', formData);
    if (response?.success) {
      yield put(reFetchPostList());
      // yield put(createPostSuccess(response.data));
    }
  } catch (error) {}
}

function* likePost({ payload }) {
  const { postId, isFromPostDetail = false } = payload;
  setAuthorization(getAccessToken());
  try {
    let response = yield call(update, `/api/posts/${postId}/likes`);
    if (response?.success) {
      const postInfo = {
        data: response?.data?.post,
        postId: postId,
        isFromPostDetail,
      };
      yield put(likePostSuccess(postInfo));
    }
  } catch (error) {}
}

function* tooglePinPost({ payload }) {
  const { postId, isFromPostDetail = false, cb } = payload.payload;
  setAuthorization(getAccessToken());
  try {
    let response = yield call(update, `/api/posts/${postId}/toggle-pin`);
    if (response?.success) {
      const postInfo = {
        postId: postId,
        isFromPostDetail,
      };
      cb(true);
      yield put(tooglePinPostSuccess(postInfo));
    }
  } catch (error) {
    cb(false);
  }
}

function* commentPost({ payload }) {
  const { values } = payload;
  var formData = new FormData();
  formData.append('post_id', values.postId);
  formData.append('content', values.comment);
  if (values.parentId) {
    formData.append('parent_id', values.parentId);
  }
  for (let i = 0; i < values.images.length; i++) {
    formData.append('images[]', values.images[i]);
  }

  setAuthorization(getAccessToken());
  try {
    let response = yield call(create, `/api/post-comments`, formData);
    if (response?.success) {
      yield put(commentPostSuccess(response.data));
    }
  } catch (error) {}
}

function* deletePost({ payload }) {
  const { post, cb, isDetail } = payload;
  const { id } = post;
  try {
    setAuthorization(getAccessToken());
    let response = yield call(deleteApi, `/api/posts/${id}`);
    if (response.success) {
      cb(response);
      if (isDetail) {
        return;
      } else {
        yield put(deletePostSuccess(post));
      }
    }
  } catch (error) {}
}

function* saveOrUnsavePost({ payload }) {
  const { postId, cb } = payload;
  try {
    setAuthorization(getAccessToken());
    let response = yield call(update, `/api/posts/${postId}/saves`);
    if (response?.success) {
      cb(response);
      yield put(saveOrUnsavePostSuccess({ postId }));
    }
  } catch (error) {
    cb(error);
  }
}

function* settingNotificationPost({ payload }) {
  const { postId, cb, status, user } = payload;
  try {
    setAuthorization(getAccessToken());
    let response = yield call(update, `/api/posts/${postId}/notifications`, {
      status,
    });
    if (response?.success) {
      cb(response);
      yield put(
        settingNotificationPostSuccess({ postId, status, user, response })
      );
    }
  } catch (error) {
    cb(error);
  }
}

function* deleteCommentPost({ payload }) {
  const { commentId, cb } = payload;
  const cmtId = commentId.id;
  try {
    setAuthorization(getAccessToken());
    let response = yield call(deleteApi, `/api/post-comments/${cmtId}`);
    if (response?.success) {
      cb(response);
      yield put(deleteCommentSuccess(commentId));
      return;
    }
  } catch (error) {}
}

function* editCommentPost({ payload }) {
  const { commentId, comment, cb, cmt } = payload;
  setAuthorization(getAccessToken());
  try {
    let response = yield call(
      create,
      `/api/post-comments/${commentId}?_method=PUT`,
      {
        content: comment,
      }
    );
    if (response?.success) {
      yield put(editCommentPostSuccess({ comment, cmt }));
      if (cb) {
        cb(response);
      }
    }
  } catch (error) {}
}

function* getCommentPostId({ payload }) {
  const postId = payload;
  setAuthorization(getAccessToken());
  try {
    const response = yield call(get, `/api/posts/${postId}/comments`);
    if (response?.success) {
      const data = {
        postId,
        comments: response.data,
      };
      yield put(getCommentPostIdSuccess(data));
    }
  } catch (error) {}
}

function* editPost({ payload }) {
  const { postTitle, id, content, deleteImages, images } = payload.input;
  var formData = new FormData();
  formData.append('title', postTitle);
  formData.append('content', content);

  for (let i = 0; i < images.length; i++) {
    formData.append('images[]', images[i]);
  }
  for (let i = 0; i < deleteImages.length; i++) {
    formData.append('delete_images[]', deleteImages[i]);
  }
  setAuthorization(getAccessToken());
  try {
    let response = yield call(
      create,
      `/api/posts/${id}?_method=PUT`,
      formData,
      {
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (response?.success) {
      yield put(editPostSuccess(response.data));
    }
  } catch (error) {}
}

export function* watchEditPost() {
  yield takeEvery(EDIT_POST, editPost);
}

export function* watchFetchPost() {
  yield takeEvery(FETCH_POST_LIST, fetchPostList);
}

export function* watchCreatePost() {
  yield takeEvery(CREATE_POST, createPost);
}

export function* watchLikePost() {
  yield takeEvery(LIKE_POST, likePost);
}

export function* watchTooglePinPost() {
  yield takeEvery(TOOGLE_PIN_POST, tooglePinPost);
}

export function* watchCommentPost() {
  yield takeEvery(COMMENT_POST, commentPost);
}

export function* watchDeletePost() {
  yield takeEvery(DELETE_POST, deletePost);
}

export function* watchSaveOrUnsavePost() {
  yield takeEvery(SAVE_OR_UNSAVE_POST, saveOrUnsavePost);
}

export function* watchSettingNotificationPost() {
  yield takeEvery(SETTING_NOTIFICATION_POST, settingNotificationPost);
}

export function* watchGetPostById() {
  yield takeEvery(GET_POST_BY_ID, getPostById);
}

export function* watchDeleteCommentPostById() {
  yield takeEvery(DELETE_COMMENT_POST, deleteCommentPost);
}

export function* watchEditCommentPost() {
  yield takeEvery(EDIT_COMMENT_POST, editCommentPost);
}

export function* watchGetCommentPostId() {
  yield takeEvery(GET_COMMENT_POST_ID, getCommentPostId);
}

export function* watchGetPostIdNonAuth() {
  yield takeEvery(GET_POST_NON_AUTH, getPostIdNonAuth);
}

function* communitySaga() {
  yield all([
    fork(watchCreatePost),
    fork(watchFetchPost),
    fork(watchLikePost),
    fork(watchCommentPost),
    fork(watchDeletePost),
    fork(watchSaveOrUnsavePost),
    fork(watchSettingNotificationPost),
    fork(watchGetPostById),
    fork(watchDeleteCommentPostById),
    fork(watchEditCommentPost),
    fork(watchGetCommentPostId),
    fork(watchEditPost),
    fork(watchGetPostIdNonAuth),
    fork(watchTooglePinPost),
  ]);
}

export default communitySaga;
