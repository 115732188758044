import React, { useState } from 'react';
import BasicModal from 'src/components/common/BasicModal';
import { PAYMENT_TYPE } from '../../constants';
import PaymentDetail from './PaymentDetail';
import PaymentList from './PaymentList';
import PaymentCreditCardDetail from './PaymentCreditCardDetail';
import i18next from 'i18next';

import icon_back from 'src/assets/images/Icons/backIcon.svg';
const { CREDIT_CARD } = PAYMENT_TYPE;

const PaymentPopup = ({
  isOpen,
  onClose,
  onClickQuestionFavorite,
  handleBack,
}) => {
  const [pageDetail, setPageDetail] = useState('');
  const isRouterDetail = pageDetail !== CREDIT_CARD && pageDetail !== '';

  const isCreditCard = pageDetail === CREDIT_CARD.key;

  const handleClick = (page) => {
    setPageDetail(page);
  };

  const handleClickBack = () => {
    if (pageDetail === '') {
      handleBack();
    }
    setPageDetail('');
  };

  const handleCloseModal = () => {
    onClose();
    setPageDetail('');
    onClickQuestionFavorite && onClickQuestionFavorite();
  };

  let titleModal = `${i18next.t('buy')} beebux`;
  titleModal = isCreditCard ? i18next.t('credit_card_info') : titleModal;
  titleModal =
    pageDetail === 'BANK_TRANSFER' ? i18next.t('bank_transfer') : titleModal;
  titleModal =
    pageDetail === 'BANK_TRANSFER' ? i18next.t('bank_transfer') : titleModal;
  titleModal = pageDetail === 'momo' ? i18next.t('zalopay') : titleModal;
  return (
    <BasicModal
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      title={titleModal}
      className={`modal-payment ${isCreditCard ? 'credit-card' : ''}`}
      backHandle={
        <img
          src={icon_back}
          alt="back"
          // onClick={() => onClose()}
          className="backIcon"
          style={{ cursor: 'pointer' }}
          onClick={handleClickBack}
        />
      }
    >
      {isCreditCard ? (
        <PaymentCreditCardDetail callback={() => handleCloseModal()} />
      ) : isRouterDetail ? (
        <PaymentDetail title={pageDetail} />
      ) : (
        <PaymentList onClick={handleClick} />
      )}
    </BasicModal>
  );
};

export default PaymentPopup;
