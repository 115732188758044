import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import HeaderPage from './AuthLayout/HeaderPage';
import FooterNonAuth from './FooterNonAuth';

class PostNonAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.capitalizeFirstLetter.bind(this);
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    document.title = currentage + ' | Pombeebee';
  }
  render() {
    return (
      <React.Fragment>
        <HeaderPage />
        {this.props.children}
        <FooterNonAuth />
      </React.Fragment>
    );
  }
}

export default withRouter(PostNonAuth);
