import moment from 'moment';
import { DateTime } from 'luxon';

export const convertToLocalTime = (timeStr) => {
  const dateTimeInLondon = DateTime.fromFormat(timeStr, 'yyyy-MM-dd HH:mm:ss', {
    zone: 'UTC',
  });
  const dateTimeInLocal = dateTimeInLondon.setZone('local');
  return dateTimeInLocal.toFormat('HH:mm dd-MM-yyyy');
};
export const formatDateTime = (value, format) => {
  const DATE_TIME_DEFAULT_FORMAT = format ? format : 'DD-MM-YYYY';
  const { defaultValue, inputFormat, outputFormat } = {
    defaultValue: '',
    inputFormat: '',
    outputFormat: DATE_TIME_DEFAULT_FORMAT,
  };
  if (!value) return defaultValue;
  return moment(value, inputFormat).format(outputFormat);
};

export function formatTime(timeStr) {
  try {
    const timeObj = new Date(timeStr);
    if (isNaN(timeObj)) {
      throw new Error('Invalid time format.');
    }
    const hour = timeObj.getHours();
    const minute = timeObj.getMinutes();
    const period = hour < 12 ? 'AM' : 'PM';
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    const formattedTime = `${hour12.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')} ${period}`;

    return formattedTime;
  } catch (error) {
    return 'Invalid time format.';
  }
}

export const formatDuration = (value, full) => {
  const formatNumber = (num) => {
    return num < 10 ? `0${num}` : num;
  };
  const number_hours = formatNumber(Math.floor(value / 3600));
  const number_minutes = formatNumber(Math.floor((value % 3600) / 60));
  const number_seconds = formatNumber(value % 60);
  if (full) {
    return `${number_hours}h ${number_minutes}m ${number_seconds}s`;
  }
  if (number_hours > 0) {
    return `${number_hours}:${number_minutes}:${number_seconds}`;
  } else if (number_minutes > 0) {
    return `00:${number_minutes}:${number_seconds}`;
  } else {
    return `00:00:${number_seconds}`;
  }
};
