import {
  COMMENT_POST,
  COMMENT_POST_SUCCESS,
  CREATE_POST,
  CREATE_POST_SUCCESS,
  DELETE_COMMENT_POST,
  DELETE_POST,
  DELETE_POST_SUCCESS,
  EDIT_COMMENT_POST,
  FETCH_POST_LIST,
  FETCH_POST_LIST_SUCCESS,
  GET_POST_BY_ID,
  GET_POST_BY_ID_SUCCESS,
  LIKE_POST,
  LIKE_POST_SUCCESS,
  SAVE_OR_UNSAVE_POST,
  SAVE_OR_UNSAVE_POST_SUCCESS,
  SETTING_NOTIFICATION_POST,
  DELETE_COMMENT_SUCCESS,
  EDIT_COMMENT_POST_SUCCESS,
  SETTING_NOTIFICATION_POST_SUCCESS,
  GET_COMMENT_POST_ID,
  GET_COMMENT_POST_ID_SUCCESS,
  EDIT_POST,
  EDIT_POST_SUCCESS,
  TOGGLE_POST_COMMENT,
  GET_POST_NON_AUTH,
  TOOGLE_PIN_POST_FAIL,
  TOOGLE_PIN_POST_SUCCESS,
  TOOGLE_PIN_POST,
} from './constants';

export const fetchPostList = (payload) => ({
  type: FETCH_POST_LIST,
  payload,
});

export const fetchPostListSuccess = (payload) => ({
  type: FETCH_POST_LIST_SUCCESS,
  payload,
});

export const creatPost = (payload) => ({
  type: CREATE_POST,
  payload,
});

export const createPostSuccess = (payload) => ({
  type: CREATE_POST_SUCCESS,
  payload,
});

export const likePost = (payload) => ({
  type: LIKE_POST,
  payload,
});

export const likePostSuccess = (payload) => ({
  type: LIKE_POST_SUCCESS,
  payload,
});

export const tooglePinPost = (payload) => ({
  type: TOOGLE_PIN_POST,
  payload,
});

export const tooglePinPostSuccess = (payload) => ({
  type: TOOGLE_PIN_POST_SUCCESS,
  payload,
});

export const tooglePinPostFail = (payload) => ({
  type: TOOGLE_PIN_POST_FAIL,
  payload,
});

export const commentPost = (payload) => ({
  type: COMMENT_POST,
  payload,
});

export const commentPostSuccess = (payload) => {
  return {
    type: COMMENT_POST_SUCCESS,
    payload,
  };
};

export const deletePost = (payload) => ({
  type: DELETE_POST,
  payload,
});

export const deletePostSuccess = (payload) => ({
  type: DELETE_POST_SUCCESS,
  payload,
});

export const saveOrUnsavePost = (payload) => ({
  type: SAVE_OR_UNSAVE_POST,
  payload,
});

export const saveOrUnsavePostSuccess = (payload) => ({
  type: SAVE_OR_UNSAVE_POST_SUCCESS,
  payload,
});

export const settingNotificationPost = (payload) => ({
  type: SETTING_NOTIFICATION_POST,
  payload,
});

export const settingNotificationPostSuccess = (payload) => ({
  type: SETTING_NOTIFICATION_POST_SUCCESS,
  payload,
});

export const getPostById = (payload) => ({
  type: GET_POST_BY_ID,
  payload,
});

export const getPostByIdNonAuth = (payload) => ({
  type: GET_POST_NON_AUTH,
  payload,
});

export const getPostByIdSuccess = (payload) => ({
  type: GET_POST_BY_ID_SUCCESS,
  payload,
});

export const deleteCommentPost = (payload) => ({
  type: DELETE_COMMENT_POST,
  payload,
});

export const deleteCommentSuccess = (payload) => {
  return {
    type: DELETE_COMMENT_SUCCESS,
    payload,
  };
};

export const editCommentPost = (payload) => ({
  type: EDIT_COMMENT_POST,
  payload,
});

export const editCommentPostSuccess = (payload) => ({
  type: EDIT_COMMENT_POST_SUCCESS,
  payload,
});

export const getCommentPostId = (payload) => {
  return {
    type: GET_COMMENT_POST_ID,
    payload,
  };
};

export const getCommentPostIdSuccess = (payload) => ({
  type: GET_COMMENT_POST_ID_SUCCESS,
  payload,
});

export const editPost = (payload) => ({
  type: EDIT_POST,
  payload,
});

export const editPostSuccess = (payload) => ({
  type: EDIT_POST_SUCCESS,
  payload,
});

export const togglePostComment = (payload) => ({
  type: TOGGLE_POST_COMMENT,
  payload,
});
