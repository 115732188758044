import { GET_USER_WALLET_SUCCESS } from './constants';

const INIT_STATE = {
  wallet: '',
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_WALLET_SUCCESS: {
      return {
        ...state,
        wallet: action.payload,
      };
    }
    default:
      return { ...state };
  }
};

export default Users;
