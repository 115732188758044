import i18next from 'i18next';

import bankTransfer from 'src/assets/images/Icons/Bank.svg';
import zaloIcon from 'src/assets/images/Icons/icon-zalo.svg';
import iconWallet from 'src/assets/images/Icons/icon-wallet.svg';
import momoIcon from 'src/assets/images/Icons/Momo.svg';

export const TRANSACTION_MODEL = {
  TOPUP: 'TOPUP',
  UNLOCK_ANSWER: 'UNLOCK_ANSWER',
  BUY_CHARACTER: 'BUY_CHARACTER',
};

export const PAYMENT_TYPE = {
  CREDIT_CARD: {
    value: () => i18next.t('credit_card'),
    key: 'CREDIT_CARD',
  },
  BANK_TRANSFER: {
    value: () => i18next.t('bank_transfer'),
    key: 'BANK_TRANSFER',
  },
  MOMO: {
    value: () => i18next.t('momo'),
    key: 'MOMO',
  },
  ZALOPAY: {
    value: () => i18next.t('zalopay'),
    key: 'ZALOPAY',
  },
};

const { CREDIT_CARD, BANK_TRANSFER, MOMO, ZALOPAY } = PAYMENT_TYPE;

export const PAYMENT_OPTIONS = [
  {
    id: 1,
    title: () => CREDIT_CARD.value(),
    key: CREDIT_CARD.key,
    description: () => i18next.t('description_payment_credit_card'),
    image: iconWallet,
  },
  {
    id: 2,
    title: () => BANK_TRANSFER.value(),
    key: BANK_TRANSFER.key,
    description: () =>
      i18next.t('description_payment', {
        method: BANK_TRANSFER.value().toLocaleLowerCase(),
      }),
    image: bankTransfer,
  },
  {
    id: 3,
    title: () => MOMO.value(),
    key: MOMO.key,
    description: () =>
      i18next.t('description_payment', {
        method:
          MOMO.value().toLocaleLowerCase() &&
          MOMO.value().toLocaleLowerCase().charAt(0).toUpperCase() +
            MOMO.value().toLocaleLowerCase().slice(1),
      }),
    image: momoIcon,
  },
  {
    id: 4,
    title: () => ZALOPAY.value(),
    key: ZALOPAY.key,
    description: () =>
      i18next.t('description_payment', {
        method:
          ZALOPAY.value().toLocaleLowerCase() &&
          ZALOPAY.value().toLocaleLowerCase().charAt(0).toUpperCase() +
            ZALOPAY.value().toLocaleLowerCase().slice(1),
      }),
    image: zaloIcon,
  },
];

export const GENDER_TYPE = {
  FEMALE: { key: 'FEMALE', value: () => i18next.t('user_profile.female') },
  MALE: { key: 'MALE', value: () => i18next.t('user_profile.male') },
  NO_SAY: {
    key: 'NO_SAY',
    value: () => i18next.t('user_profile.prefer_not_say'),
  },
};

export const DATA_GENDER = [
  { id: 1, label: 'Female', value: GENDER_TYPE.FEMALE.key },
  { id: 2, label: 'Male', value: GENDER_TYPE.MALE.key },
  { id: 3, label: 'Prefer not say', value: GENDER_TYPE.NO_SAY.key },
];

export const PRICE_BEEBUX = [
  {
    id: 0,
    currency: 20000,
    currencyBeebux: 200,
  },
  {
    id: 1,
    currency: 30000,
    currencyBeebux: 300,
  },
  {
    id: 2,
    currency: 50000,
    currencyBeebux: 500,
  },
  {
    id: 3,
    currency: 100000,
    currencyBeebux: 1000,
  },
  {
    id: 4,
    currency: 200000,
    currencyBeebux: 2000,
  },
  {
    id: 5,
    currency: 500000,
    currencyBeebux: 5000,
  },
];
