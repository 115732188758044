import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from '../../redux/actions';

//Import Images
import libraryIcon from '../../assets/images/Icons/Library.svg';
import beeIcon from 'src/assets/images/Icons/beeIcon.png';
import notiIcon from '../../assets/images/Icons/icon-bell.svg';
import profileIcon from '../../assets/images/Icons/icon-user.svg';
import communityIcon from '../../assets/images/Icons/Community.svg';
import course from '../../assets/images/Icons/course.svg';
import PaymentPopup from 'src/pages/Transactions/components/PaymentPopup';
import NotificationList from 'src/pages/Notification';
import { openNotifications } from 'src/redux/notifications/actions';
import { closeNotifications } from 'src/redux/notifications/actions';
import { useTranslation } from 'react-i18next';
import SidebarItem from 'src/components/SidebarItem';

//i18n

function LeftSidebarMenu(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const unreadNoti = useSelector(
    (state) => state.Notifications.unreadNotification
  );
  const unReadMessages = useSelector((state) => state.Chat.unReadMessages);

  let notiHelpCenter = useSelector((state) => state.Chat.helpCenter?.unRead);

  const toggleTab = (tab) => {
    props.setActiveTab(tab);
  };

  const activeTab = history.location.pathname || props.activeTab;

  const handleClick = (page) => {
    toggleTab(page);
    history.push(page);
  };

  const handleShowModalNoti = () => {
    dispatch(openNotifications());
  };
  const handlecloseModalNoti = () => {
    dispatch(closeNotifications());
  };

  const showNotiList = useMemo(() => <NotificationList />, []);

  return (
    <React.Fragment>
      <PaymentPopup />
      {showNotiList}
      <div className="side-menu flex-lg-column my-auto">
        <Nav
          pills
          className="side-menu-nav justify-content-center"
          role="tablist"
        >
          <SidebarItem
            Icon={libraryIcon}
            id="Library"
            onClick={() => {
              handleClick('/library');
              handlecloseModalNoti();
            }}
            className={classnames({
              active: activeTab.includes('library'),
            })}
            nameItem={t('sidebar.library')}
          />
          <SidebarItem
            Icon={communityIcon}
            id="Community"
            onClick={() => {
              handleClick('/community');
              handlecloseModalNoti();
            }}
            className={classnames({
              active: activeTab.includes('/community'),
            })}
            nameItem={t('sidebar.community')}
          />
          <NavItem
            id="AIActivities"
            onClick={() => {
              handleClick('/chat');
              handlecloseModalNoti();
            }}
            className={classnames({
              active: activeTab === '/chat',
            })}
          >
            <div className="circle_out">
              <div className="circle_in">
                <NavLink id="pills-chat-tab">
                  {unReadMessages ? (
                    <div
                      className="sidebar-noti"
                      style={{ backgroundColor: '#D53334', color: '#fff' }}
                    >
                      {unReadMessages}
                    </div>
                  ) : null}
                  <img src={beeIcon} alt="chat" width="24px" />
                </NavLink>
              </div>
            </div>
            <h6>{t('sidebar.ai_activities').toUpperCase()}</h6>
          </NavItem>
          <SidebarItem
            Icon={course}
            id="course"
            onClick={() => {
              handleClick('/course');
              handlecloseModalNoti();
            }}
            className={classnames({
              active: activeTab.includes('/course'),
            })}
            nameItem={t('sidebar.course')}
          />
          <NavItem
            id="Notification"
            onClick={() => handleShowModalNoti()}
            className={classnames({
              active: activeTab === '/notification',
            })}
          >
            <div className="circle_out">
              <div className="circle_in">
                <NavLink
                  id="pills-chat-tab"
                  className={classnames({
                    active: activeTab === '/notification',
                  })}
                >
                  {unreadNoti ? (
                    <div className="sidebar-noti">{unreadNoti}</div>
                  ) : null}
                  <img src={notiIcon} alt="library" width="24px" />
                </NavLink>
              </div>
            </div>
            <h6>{t('sidebar.notification').toUpperCase()}</h6>
          </NavItem>
          <SidebarItem
            Icon={profileIcon}
            id="Profiles"
            onClick={() => {
              handleClick('/profile');
              handlecloseModalNoti();
            }}
            className={classnames({
              active:
                activeTab === '/profile' ||
                activeTab === '/transaction' ||
                activeTab === '/change-password',
            })}
            nameItem={t('sidebar.profile')}
            unReadHelp={notiHelpCenter ? notiHelpCenter : 0}
          />
        </Nav>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { user } = state.Auth;
  return {
    ...state.Layout,
    user,
  };
};

export default connect(mapStatetoProps, {
  setActiveTab,
})(LeftSidebarMenu);
