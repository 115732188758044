export const IMAGEFILES = ['jpg', 'png', 'jpeg', 'gif'];
export const VIDEOFILES = ['mp4', 'mov', 'avi', 'webm', 'f4v'];
export const DATE_FORMAT = 'yyyy-MM-dd';

export const PAYMENT_STATUS = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
};

export const PROFILE_OPTIONS = {
  PROFILE: 'PROFILE',
  SETTINGS: 'SETTINGS',
};

export const NOTIFICATION_TYPES = {
  NEW_QUESTION: 'NEW_QUESTION',
  POST_COMMENT: 'POST_COMMENT',
  POST_LIKE: 'POST_LIKE',
};

export const CONVERSATION_TYPE = {
  TYPE_BOT: 'BOT',
  TYPE_HELP_CENTER: 'HELP_CENTER',
  BOT_FIND_VOCABULARY: 'BOT_FIND_VOCABULARY',
  BOT_WRITE_ESSAY: 'BOT_WRITE_ESSAY',
  BOT_SCORE_ESSAY: 'BOT_SCORE_ESSAY',
  BOT_DAILY_CHAT: 'BOT_DAILY_CHAT',
  BOT_PRONUNCIATION: 'BOT_PRONUNCIATION',
  BOT_SPEAKING: 'BOT_SPEAKING',
  READING_LISTENING: 'READING_LISTENING',
  SPEAKING_TEST: 'SPEAKING_TEST',
  GROUP: 'GROUP',
  GRADE_MY_SPEECH: 'GRADE_MY_SPEECH',
};

export const MESSAGE_TYPE = {
  TEXT: 'TEXT',
  SYSTEM: 'SYSTEM',
  ATTACHMENT: 'ATTACHMENT',
  READING_LISTENING: 'READING_LISTENING',
  IMAGE: 'IMAGE',
  DOCUMENT: 'DOCUMENT',
  VOCABULARY: 'VOCABULARY',
  READING: 'READING',
  LISTENING: 'LISTENING',
};

export const LABEL_TRACKING = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  OPEN_LIBRARY: 'OPEN_LIBRARY',
  VIEW_LIBRARY_DETAIL: 'VIEW_LIBRARY_DETAIL',
  OPEN_COMMUNITY: 'OPEN_COMMUNITY',
  VIEW_POST_DETAIL: 'VIEW_POST_DETAIL',
  OPEN_AI: 'OPEN_AI',
  CLICK_NEW_CHAT: 'CLICK_NEW_CHAT',
  CLICK_BOT_DAILY_CHAT: 'CLICK_BOT_DAILY_CHAT',
  CLICK_BOT_FIND_VOCABULARY: 'CLICK_BOT_FIND_VOCABULARY',
  CLICK_BOT_WRITE_ESSAY: 'CLICK_BOT_WRITE_ESSAY',
  CLICK_BOT_SCORE_ESSAY: 'CLICK_BOT_SCORE_ESSAY',
  CLICK_PURCHASE_CHARACTERS: 'CLICK_PURCHASE_CHARACTERS',
  CLICK_BUY_BEEBUX: 'CLICK_BUY_BEEBUX',
  PLACE_ORDER: 'PLACE_ORDER',
};

export const ACTION_TRACKING = {
  OPEN: 'OPEN',
  VIEW: 'VIEW',
  CLICK: 'CLICK',
};

export const CATEGORY_TRACKING = {
  USER: 'USER',
  AI: 'AI',
  COMMUNITY: 'COMMUNITY',
  LIBRARY: 'LIBRARY',
  NOTIFYCATION: 'NOTIFYCATION',
};

export const QUESTION_TYPE = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
};

export const TYPE_ACTION_GROUP = {
  DELETE_MEMBER: 'DELETE_MEMBER',
  ADD_MEMBER: 'ADD_MEMBER',
  LEAVE_GROUP: 'LEAVE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  ACTIVATE_GROUP: 'ACTIVATE_GROUP',
};

export const TYPE_USER = {
  ADMIN: 'ADMIN',
  TEACHER: 'TEACHER',
  MEMBER: 'MEMBER',
};

export const TYPE_MEMBER = {
  ADMIN: 'ADMIN',
  TEACHER: 'TEACHER',
  MEMBER: 'MEMBER',
};

export const TYPE_MEMBER_LOWER = {
  admin: 'Admin',
  teacher: 'Teacher',
  member: 'Member',
};

export const CONVERSATION_STATUS = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  OUT_GROUP: 'OUT_GROUP',
};
