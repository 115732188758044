import i18next from 'i18next';
import React from 'react';
import { useHistory } from 'react-router-dom';

const FooterNonAuth = () => {
  const history = useHistory();
  const handleClickLogin = () => {
    history.push('/login');
  };
  const handleClickRegister = () => {
    history.push('/register');
  };
  return (
    <div className="footer_post_non">
      <div className="filter-action">
        <button
          className="apply_btn button-bg"
          onClick={() => handleClickLogin()}
        >
          {i18next.t('Login').toUpperCase()}
        </button>
        <button
          className="reset_btn  button-outline"
          onClick={() => handleClickRegister()}
        >
          {i18next.t('Register').toUpperCase()}
        </button>
      </div>
    </div>
  );
};

export default FooterNonAuth;
