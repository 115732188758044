import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

//import routes
import { authProtectedRoutes, postRouters, publicRoutes } from './routes';

//import layouts
import NonAuthLayout from '../layouts/NonAuth';
import AuthLayout from '../layouts/AuthLayout/';
import config from 'src/config';
import PostNonAuth from 'src/layouts/PostNonAuth';

// handle auth and authorization
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isPostRouter,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isPostRouter) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }
        if (isAuthProtected && !localStorage.getItem(config.STORAGE_KEY_USER)) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
        // authorised so return component
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

/**
 * Main Route component
 */
const Routes = (props) => {
  return (
    // rendering the router with layout
    <BrowserRouter>
      <React.Fragment>
        <Suspense fallback={<div></div>}>
          <Switch>
            <AppRoute
              path={postRouters.path}
              layout={
                localStorage.getItem(config.STORAGE_KEY_USER)
                  ? AuthLayout
                  : PostNonAuth
              }
              component={postRouters.component}
              isAuthProtected={false}
              isPostRouter={true}
            />
            {/* public routes */}
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
            {/* private/auth protected routes */}
            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={AuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Suspense>
      </React.Fragment>
    </BrowserRouter>
  );
};

export default Routes;
