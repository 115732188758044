import { all, call, fork, put, delay, takeEvery } from 'redux-saga/effects';

import { APIClient, setAuthorization } from '../../helpers/apiClient';
import { chatParser } from '../../helpers/chatParser';
import {
  fetchAllConversationsSuccess,
  markAsRead,
  getNewUserSuccess,
  creatChatSuccess,
  loadMoreUserSuccess,
  fetchMessagesSuccess,
  fetchingMessageFailed,
  getHelpCenterSuccess,
  addMessage,
  getChatBotTypeSuccess,
  fetchGroupMembersSuccess,
  fetchUserSuccess,
  fetchUserFalse,
  addMemberSuccess,
  removeMemberSuccess,
  leaveGroupSuccess,
  toggleActivateGroupSuccess,
  editAvatarGroupFail,
  editMessageSuccess,
  deleteMessageSuccess,
  deleteMessageFail,
  toggleNotificationSuccess,
  getEnglishClubSuccess,
} from './actions';
import { messageParser } from '../../helpers/chatParser';
import { getAccessToken } from '../../helpers/user';
import {
  GET_NEW_CONVERSATION,
  FULL_CONVERSATION,
  SEND_MESSAGE,
  READ_MESSAGE,
  CREATE_CHAT,
  LOAD_MORE_CONVERSATION,
  FETCH_MESSAGE,
  GET_HELP_CENTER,
  GET_CHAT_BOT_TYPE,
  FETCH_GROUP_MEMBERS,
  FETCH_USER,
  ADD_MEMBER_REQUEST,
  REMOVE_MEMBER_REQUEST,
  LEAVE_GROUP_REQUEST,
  EDIT_GROUP_AVATAR_REQUEST,
  TOGGLE_ACTIVATE_GROUP,
  EDIT_MESSAGE_REQUEST,
  DELETE_MESSAGE_REQUEST,
  TOGGLE_NOTIFICATION,
  GET_ENGLISH_CLUB,
} from './constants';
import { MESSAGE_TYPE } from 'src/constants';
import { joinClubSuccess } from '../actions';

const get = new APIClient().get;
const create = new APIClient().create;
const deleteMethod = new APIClient().delete;
const update = new APIClient().update;

function* getNewUserConversation({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      get,
      '/conversations?conversation_id=' + payload
    );
    let listChat = [];
    response.data.data.forEach((conversation) => {
      listChat.push(chatParser(conversation));
    });
    if (listChat.length > 0) {
      yield put(getNewUserSuccess(listChat[0]));
    }
  } catch (error) {}
}

function* fetchAllConversations({ payload }) {
  try {
    setAuthorization(getAccessToken());
    let response;
    if (!payload) {
      response = yield call(get, `/api/conversations`);
    } else {
      response = yield call(get, `/api/conversations?search=${payload}`);
    }
    let listChat = [];
    response.data.data.forEach((conversation) => {
      listChat.push(chatParser(conversation));
    });
    yield put(
      fetchAllConversationsSuccess({
        listChat,
        lastPage: response.data.last_page,
        search: payload !== undefined ? true : false,
        current_page: response.data.current_page,
      })
    );
  } catch (error) {
    console.log(error);
  }
}

function* send({ payload }) {
  var formData = new FormData();
  let type;
  if (payload.isFileMessage || payload.isImageMessage) {
    type = MESSAGE_TYPE.ATTACHMENT;
    formData.append('attachment', payload.fileData);
    if (payload.isImageMessage) {
      formData.append('message', payload.message);
    }
  } else {
    type = MESSAGE_TYPE.TEXT;
    formData.append('message', payload.message);
  }
  formData.append('conversation_id', payload.conversation_id);
  formData.append('type', type);
  formData.append('option', payload.option);
  if (payload.parent_id) {
    formData.append('parent_id', payload.parent_id);
  }
  setAuthorization(getAccessToken());
  try {
    let response;
    if (type === MESSAGE_TYPE.TEXT) {
      response = yield call(create, '/api/messages', formData);
    } else {
      response = yield call(create, '/api/messages', formData, {
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    let newMessage = messageParser(response.data);
    yield put(addMessage(newMessage));
  } catch (error) {
    yield put(fetchingMessageFailed(payload.conversation_id));
  }
}

function* read({ payload }) {
  try {
    setAuthorization(getAccessToken());
    yield call(update, '/api/conversations/' + payload + '/mark-as-read');
    yield put(markAsRead(payload));
  } catch (error) {}
}

function* createChat({ payload }) {
  setAuthorization(getAccessToken());
  if (payload.is_public_group) {
    var formData = new FormData();
    formData.append('is_public_group', payload.is_public_group);
    formData.append('name', payload.name);
    formData.append('type', payload.type);
    formData.append('image', payload.image);
    for (let i = 0; i < payload.member_ids.length; i++) {
      formData.append('member_ids[]', payload.member_ids[i]);
    }
    try {
      let response = yield call(create, '/api/conversations', formData);
      let newChat = chatParser(response.data);
      yield put(creatChatSuccess(newChat));
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      let response = yield call(create, '/api/conversations', payload);
      let newChat = chatParser(response.data);
      yield put(creatChatSuccess(newChat));
    } catch (error) {
      console.log(error);
    }
  }
}

function* loadMoreUser({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      get,
      `/api/conversations?page=${payload.page}&search=${payload.search}`
    );

    let listChat = [];
    response.data.data.forEach((conversation) => {
      listChat.push(chatParser(conversation));
    });
    yield delay(1000);
    yield put(
      loadMoreUserSuccess({
        listChat,
        current_page: response.data.current_page,
      })
    );
  } catch (error) {}
}

function* fetchMessages({ payload }) {
  setAuthorization(getAccessToken());
  const { conversation_id, currentPage } = payload;
  try {
    let response = yield call(
      get,
      `/api/messages?conversation_id=${conversation_id}&page=${currentPage}`
    );
    const lastPage = response.data.last_page;
    let listItems = [];
    if (response.data.data) {
      response.data.data.forEach((item) => {
        listItems.push(messageParser(item));
      });
    }
    yield put(
      fetchMessagesSuccess(
        conversation_id,
        listItems.length ? listItems.reverse() : listItems,
        lastPage,
        currentPage
      )
    );
  } catch (error) {}
}

function* getHelpCenter() {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, '/api/conversations/help-center');
    let helpCenterData = chatParser(response.data);
    yield put(getHelpCenterSuccess(helpCenterData));
  } catch (error) {}
}

function* getEnglishClub() {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, '/api/conversations/english-club');
    let englishclubData = chatParser(response.data);
    yield put(getEnglishClubSuccess(englishclubData));
    yield put(joinClubSuccess(true));
  } catch (error) {}
}

function* getChatBotType() {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(get, '/api/conversations/chatbots');
    if (response.success) {
      yield put(getChatBotTypeSuccess(response.data));
    }
  } catch (error) {}
}

function* fetchGroupMembers({ payload }) {
  const { conversation_id, params } = payload;
  if (params.keyword?.length === 0) {
    yield put(fetchGroupMembersSuccess({ isReload: true }));
  } else {
    try {
      setAuthorization(getAccessToken());
      const response = yield call(
        get,
        `/api/conversations/${conversation_id}/search-member`,
        {
          params: params,
        }
      );
      if (response.success) {
        yield put(fetchGroupMembersSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  }
}
function* fetchUserRequest({ payload }) {
  const { params } = payload;
  if (params.keyword) {
    try {
      setAuthorization(getAccessToken());
      const response = yield call(get, `api/users/search`, {
        params: params,
      });
      if (response.success) {
        yield put(fetchUserSuccess(response.data));
      }
    } catch (err) {
      yield put(fetchUserFalse(params));
    }
  } else {
    yield put(fetchUserFalse(params));
  }
}
function* addMemberRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      update,
      `/api/conversations/${payload.conversation_id}/add-member`,
      {
        user_id: payload.user?.id,
      }
    );
    if (response.success) {
      yield put(addMemberSuccess(response.data));
      payload.cb(true);
    }
  } catch (error) {
    payload.cb(false);
    console.log(error);
  }
}
function* removeMemberRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      update,
      `/api/conversations/${payload.conversation_id}/remove-member`,
      {
        user_id: payload.user?.user?.id,
      }
    );
    if (response.success) {
      yield put(removeMemberSuccess(response.data));
      payload.cb(true);
    }
  } catch (error) {
    payload.cb(false);
    console.log(error);
  }
}
function* leaveGroupRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      update,
      `/api/conversations/${payload.conversation_id}/leave`,
      {
        user_id: payload.user?.id,
      }
    );
    if (response.success) {
      yield put(leaveGroupSuccess(response.data));
      payload.cb(true);
    }
  } catch (error) {
    payload.cb(false);
    console.log(error);
  }
}

function* editAvatarGroup({ payload }) {
  var formData = new FormData();
  formData.append('image', payload.fileUpload);
  formData.append('name', payload.name);
  try {
    setAuthorization(getAccessToken());
    yield call(
      create,
      `/api/conversations/${payload.conversation_id}?_method=PUT`,
      formData
    );
  } catch (error) {
    console.log(error);
  }
}
function* toggleActivateGroup({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      update,
      `/api/conversations/${payload.conversation_id}/toggle-activation`
    );
    if (response.success) {
      yield put(toggleActivateGroupSuccess(response.data));
      payload.cb(true);
    }
  } catch (error) {
    payload.cb(false);
    console.log(error);
  }
}

function* editMessageRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(update, `/api/messages/${payload.idMessage}`, {
      message: payload.content,
    });
    if (response.success) {
      yield put(editMessageSuccess(messageParser(response.data)));
    }
  } catch (error) {
    console.log(error);
    yield put(editAvatarGroupFail);
  }
}

function* deleteMessageRequest({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      deleteMethod,
      `/api/messages/${payload.idMessage}`
    );
    if (response.success) {
      yield put(deleteMessageSuccess(messageParser(response.data)));
    }
  } catch (error) {
    yield put(deleteMessageFail());
  }
}

function* toggleNotification({ payload }) {
  try {
    setAuthorization(getAccessToken());
    const response = yield call(
      update,
      `/api/conversations/${payload}/toggle-notification`
    );
    if (response.success) {
      yield put(toggleNotificationSuccess(response.data));
    }
  } catch (error) {}
}

export function* watchToggleNotification() {
  yield takeEvery(TOGGLE_NOTIFICATION, toggleNotification);
}

export function* watchDeleteMessageRequest() {
  yield takeEvery(DELETE_MESSAGE_REQUEST, deleteMessageRequest);
}

export function* watchEditMessageRequest() {
  yield takeEvery(EDIT_MESSAGE_REQUEST, editMessageRequest);
}

export function* watchToggleActivateGroup() {
  yield takeEvery(TOGGLE_ACTIVATE_GROUP, toggleActivateGroup);
}

export function* watchEditAvatarRequest() {
  yield takeEvery(EDIT_GROUP_AVATAR_REQUEST, editAvatarGroup);
}

export function* watchLeaveGroupRequest() {
  yield takeEvery(LEAVE_GROUP_REQUEST, leaveGroupRequest);
}

export function* watchRemoveMemberRequest() {
  yield takeEvery(REMOVE_MEMBER_REQUEST, removeMemberRequest);
}

export function* watchAddMemberRequest() {
  yield takeEvery(ADD_MEMBER_REQUEST, addMemberRequest);
}

export function* watchFetchUserRequest() {
  yield takeEvery(FETCH_USER, fetchUserRequest);
}

export function* watchFetchGroupMembers() {
  yield takeEvery(FETCH_GROUP_MEMBERS, fetchGroupMembers);
}

export function* watchSetFull() {
  yield takeEvery(FULL_CONVERSATION, fetchAllConversations);
}

export function* watchSend() {
  yield takeEvery(SEND_MESSAGE, send);
}

export function* watchRead() {
  yield takeEvery(READ_MESSAGE, read);
}

export function* watchGetNewUserConversation() {
  yield takeEvery(GET_NEW_CONVERSATION, getNewUserConversation);
}

export function* watchCreateChat() {
  yield takeEvery(CREATE_CHAT, createChat);
}

export function* watchLoadMoreUser() {
  yield takeEvery(LOAD_MORE_CONVERSATION, loadMoreUser);
}

export function* watchFetchMessages() {
  yield takeEvery(FETCH_MESSAGE, fetchMessages);
}

export function* watchGetHelpCenter() {
  yield takeEvery(GET_HELP_CENTER, getHelpCenter);
}

export function* watchGetEnglishClub() {
  yield takeEvery(GET_ENGLISH_CLUB, getEnglishClub);
}

export function* watchGetChatBotType() {
  yield takeEvery(GET_CHAT_BOT_TYPE, getChatBotType);
}

function* chatSaga() {
  yield all([
    fork(watchSetFull),
    fork(watchSend),
    fork(watchRead),
    fork(watchGetNewUserConversation),
    fork(watchCreateChat),
    fork(watchLoadMoreUser),
    fork(watchFetchMessages),
    fork(watchGetHelpCenter),
    fork(watchGetEnglishClub),
    fork(watchGetChatBotType),
    fork(watchFetchGroupMembers),
    fork(watchFetchUserRequest),
    fork(watchAddMemberRequest),
    fork(watchRemoveMemberRequest),
    fork(watchLeaveGroupRequest),
    fork(watchEditAvatarRequest),
    fork(watchToggleActivateGroup),
    fork(watchEditMessageRequest),
    fork(watchDeleteMessageRequest),
    fork(watchToggleNotification),
  ]);
}

export default chatSaga;
