import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//Import Components
import LeftSidebarMenu from './LeftSidebarMenu';
import PopupKickout from 'src/components/PopupKickout';
import { listenWebsocketEvent } from 'src/services/websocket';
import {
  addMessage,
  fetchingMessageSuccess,
  logoutUser,
  updateUserSuccess,
} from '../../redux/actions';
import {
  getUserWalletRequest,
  getUserWalletSuccess,
} from 'src/redux/users/actions';
import {
  getGeneralDataRequest,
  setInitializedData,
  getCategoriesCourse,
} from 'src/redux/masterData/actions';
import {
  getUnreadNotificationRequest,
  getNotificationsRequest,
  addNotifications,
} from 'src/redux/notifications/actions';
import {
  addConversationRealtime,
  updateConversationRealtime,
  deleteConversationRealtime,
  addMemberRealtime,
  removeMemberRealtime,
  leaveMemberRealtime,
  fetchAllConversations,
  editMessageSuccess,
  deleteMessageSuccess,
} from 'src/redux/chat/actions';
import { chatParser, messageParser } from 'src/helpers/chatParser';
import { getBankAccountRequest } from 'src/redux/transactions/actions';
import { connect } from 'react-redux';
import { userService } from 'src/services/userService';
import HeaderPage from './HeaderPage';
import Loading from 'src/components/Loading';
import { getChatBotType } from 'src/redux/chat/actions';
import { getLoggedInUser, setLoggedInUser } from 'src/helpers/authUtils';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isKickedOut: false,
      isLoading: true,
    };
    this.capitalizeFirstLetter.bind(this);
  }

  //function for capital first letter of current page pathname
  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  registerWebsocket = () => {
    if (this.props.currentUser && this.props.currentUser.user) {
      listenWebsocketEvent(this.props.currentUser.user.id, {
        NewMessageEvent: (value) => {
          this.props.addMessage(value);
          if (value.userId !== this.props.currentUser.user.id) {
            this.props.fetchingMessageSuccess(value.conversation_id);
          }
        },
        KickOutEvent: async () => {
          let logged = await userService.verifyToken();
          if (!logged) {
            this.setState({
              isKickedOut: true,
            });
          }
        },
        UpdateWalletEvent: (value) => {
          this.props.getUserWalletSuccess(value);
        },
        AddNotifications: (value) => {
          this.props.addNotifications(value);
        },
        UpdateConversation: (value) => {
          switch (value.type) {
            case 'ADD_CONVERSATION': {
              this.props.addConversationRealtime(chatParser(value.data));
              break;
            }
            case 'UPDATE_CONVERSATION': {
              this.props.updateConversationRealtime(chatParser(value.data));
              break;
            }
            case 'DELETE_CONVERSATION': {
              this.props.deleteConversationRealtime(value.data);
              break;
            }
            default: {
              console.log('invalid type');
            }
          }
        },
        UpdateMembers: async (value) => {
          switch (value.type) {
            case 'MEMBER_ADD': {
              this.props.addMemberRealtime(value.data);
              break;
            }
            case 'MEMBER_REMOVE': {
              this.props.removeMemberRealtime(value.data);
              break;
            }
            case 'MEMBER_DELETE': {
              this.props.leaveMemberRealtime(value.data);
              break;
            }
            default: {
              console.log('invalid type');
            }
          }
        },
        UpdateMessage: (value) => {
          switch (value.type) {
            case 'MESSAGE_UPDATE': {
              this.props.editMessageSuccess(messageParser(value.data));
              return;
            }
            case 'MESSAGE_DELETE': {
              this.props.deleteMessageSuccess(messageParser(value.data));
              return;
            }
            default: {
              console.log('invalid type');
            }
          }
        },
      });
    }
  };

  initFetchData() {
    this.props.getUserWalletRequest();
    this.props.getGeneralDataRequest();
    this.props.getUnreadNotificationRequest();
    this.props.getNotificationsRequest();
    this.props.getBankAccountRequest();
    this.props.getChatBotType();
    this.props.getCategoriesCourse();
  }

  async componentDidMount() {
    if (!this.props.isDataFetched) {
      let logged = await userService.verifyToken();
      if (!logged) {
        this.props.logoutUser(this.props.history);
      } else {
        this.props.updateUserSuccess(logged);
        const useStr = getLoggedInUser();
        useStr.user = logged;
        setLoggedInUser(useStr);
      }
      this.initFetchData();
      this.registerWebsocket();
      userService.log(this.props.currentUser?.user?.id);
      this.props.setInitializedData(true);
    }
    this.setState({
      isLoading: false,
    });
    //set document title according to page path name
    let currentage = this.capitalizeFirstLetter(
      this.props.location.pathname
    ).split('/')[0];
    document.title = currentage + ' | Pombeebee';
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div style={{ marginTop: '25%' }}>
          <Loading />
        </div>
      );
    }
    return (
      <React.Fragment>
        <PopupKickout isOpen={false}></PopupKickout>
        <HeaderPage />
        <div className="layout-wrapper d-lg-flex contai_structure">
          {/* left sidebar menu */}
          <LeftSidebarMenu />
          {/* render page content */}
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const currentUser = state.Auth.user;
  const isDataFetched = state.GeneralData?.initializedData;
  return { currentUser, isDataFetched };
};

export default withRouter(
  connect(mapStateToProps, {
    addMessage,
    fetchingMessageSuccess,
    logoutUser,
    getUserWalletRequest,
    getUserWalletSuccess,
    getGeneralDataRequest,
    setInitializedData,
    getUnreadNotificationRequest,
    getNotificationsRequest,
    getBankAccountRequest,
    addNotifications,
    getChatBotType,
    addConversationRealtime,
    updateConversationRealtime,
    deleteConversationRealtime,
    getCategoriesCourse,
    addMemberRealtime,
    removeMemberRealtime,
    leaveMemberRealtime,
    fetchAllConversations,
    editMessageSuccess,
    deleteMessageSuccess,
    updateUserSuccess,
  })(Index)
);
