import { LANGUAGE } from 'src/pages/Library/constants';

export const getLangBrowser = () => {
  const userLang = navigator.language || navigator.userLanguage;
  switch (userLang) {
    case 'vi-VN':
      return LANGUAGE.VI;
    case 'en':
      return LANGUAGE.EN;
    default:
      return LANGUAGE.VI;
  }
};
