import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  API_FAILED,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  OTP_REQUEST,
  RESEND_CODE,
  SEND_CODE_RESET_PW_REQUEST,
  SEND_CODE_RESET_PW_SUCCESS,
  SEND_CODE_RESET_PW_FAILED,
  RESET_PASSWORD_REQUEST,
  VERIFY_RESET_PW_SUCCESS,
  GET_IP_ADDRESS_REQUEST,
  GET_IP_ADDRESS_SUCCESS,
  GET_IP_ADDRESS_FAILED,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_FAILED,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_LANGUAGE,
  DELETE_ACCOUNT_REQUEST,
  RESEND_CODE_FAILED,
  RESET_PASSWORD_FAILED,
  CLEAR_ERROR,
  JOIN_CLUB_SUCCESS,
} from './constants';

export const joinClubSuccess = (payload) => ({
  type: JOIN_CLUB_SUCCESS,
  payload,
});

export const loginUser = (username, password, history, device_id) => ({
  type: LOGIN_USER,
  payload: { username, password, history, device_id },
});

export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});

export const registerUser = (payload) => ({
  type: REGISTER_USER,
  payload,
});

export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const forgetPassword = (email) => ({
  type: FORGET_PASSWORD,
  payload: { email },
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const apiError = (error) => ({
  type: API_FAILED,
  payload: error,
});

export const updateUser = (user) => ({
  type: UPDATE_USER,
  payload: user,
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
});

export const otpRequest = (payload) => ({
  type: OTP_REQUEST,
  payload,
});

export const resendCode = (payload) => ({
  type: RESEND_CODE,
  payload,
});

export const resendCodeFailed = (payload) => ({
  type: RESEND_CODE_FAILED,
  payload,
});

export const sendCodeResetPwRequest = (payload) => ({
  type: SEND_CODE_RESET_PW_REQUEST,
  payload,
});

export const sendCodeResetPwSuccess = (payload) => ({
  type: SEND_CODE_RESET_PW_SUCCESS,
  payload,
});

export const sendCodeResetPwFailed = (payload) => ({
  type: SEND_CODE_RESET_PW_FAILED,
  payload,
});

export const resetPasswordRequest = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordFailed = (payload) => ({
  type: RESET_PASSWORD_FAILED,
  payload,
});

export const verifyResetPwSuccess = (payload) => ({
  type: VERIFY_RESET_PW_SUCCESS,
  payload,
});

export const getIpAddressRequest = () => ({
  type: GET_IP_ADDRESS_REQUEST,
});

export const getIpAddressSuccess = (payload) => ({
  type: GET_IP_ADDRESS_SUCCESS,
  payload,
});

export const getIpAddressFailed = () => ({
  type: GET_IP_ADDRESS_FAILED,
});

export const uploadAvatarRequest = (payload) => ({
  type: UPLOAD_AVATAR_REQUEST,
  payload,
});

export const uploadAvatarSuccess = (payload) => ({
  type: UPLOAD_AVATAR_SUCCESS,
  payload,
});

export const uploadAvatarFailed = (payload) => ({
  type: UPLOAD_AVATAR_FAILED,
  payload,
});

export const updateUserProfileRequest = (payload) => ({
  type: UPDATE_USER_PROFILE_REQUEST,
  payload,
});

export const updateUserProfileSuccess = (user) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload: user,
});

export const updateUserProfileFail = (payload) => ({
  type: UPDATE_USER_PROFILE_FAILED,
  payload,
});

export const updateLanguage = (payload) => ({
  type: UPDATE_LANGUAGE,
  payload,
});

export const deleteAccountRequest = (payload) => ({
  type: DELETE_ACCOUNT_REQUEST,
  payload,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});
