import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Chat from './chat/reducers';
import Layout from './layout/reducer';
import Users from './users/reducer';
import Questions from './questions/reducer';
import Notifications from './notifications/reducer';
import Transactions from './transactions/reducer';
import GeneralData from './masterData/reducer';
import Community from './communities/reducers';
import Course from './course/reducer';

export default combineReducers({
  Auth,
  Chat,
  Users,
  Layout,
  Questions,
  Notifications,
  Transactions,
  GeneralData,
  Community,
  Course,
});
